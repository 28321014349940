import React from 'react'
import Helmet from 'react-helmet'

const Comments = ({ page_id }) => (
    <div>
        <div id="disqus_thread"></div>
        <Helmet>
            <script>{`
            /**
            *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
            *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables*/
            /*
            var disqus_config = function () {
            this.page.url = window.location.href;  // Replace PAGE_URL with your page's canonical URL variable
            this.page.identifier = ${page_id}; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
            };
            */
            (function() { // DON'T EDIT BELOW THIS LINE
            var d = document, s = d.createElement('script');
            s.src = 'https://superrational.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
            `}
            </script>
            <noscript>{`Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">
            comments powered by Disqus.</a>`}</noscript>
        </Helmet>
    </div>
)

export default Comments
                            